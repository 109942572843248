import { VideoSection } from 'src/core/contentManager/entities';
import YoutubeVideo from 'src/core/common/components/common/YoutubeVideo';
import ContentManagerSection from 'src/core/contentManager/components/organisms/ContentManagerSection';
import { useInView } from 'src/core/common/hooks';
import styles from './SectionVideo.module.scss';

type SectionVideoProps = {
  className?: string;
  sectionContent: VideoSection;
  isLazy?: boolean;
};

const IN_VIEW_ROOT_MARGIN = '750px 0px';

const SectionVideo = ({ className = '', sectionContent, isLazy = true }: SectionVideoProps) => {
  const { title, videoId, videoAlt } = sectionContent;

  const { ref, inView } = useInView({
    threshold: 0,
    skip: !isLazy,
    triggerOnce: true,
    rootMargin: IN_VIEW_ROOT_MARGIN,
    fallbackInView: true,
  });

  const shouldBeInView = !isLazy || inView;

  return (
    <ContentManagerSection ref={ref} className={className} contentClassName={styles.content}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {shouldBeInView && <YoutubeVideo title={videoAlt} id={videoId} />}
    </ContentManagerSection>
  );
};

export default SectionVideo;
