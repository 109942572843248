import cn from 'classnames';
import LiteYouTubeEmbed, { LiteYouTubeProps } from 'react-lite-youtube-embed';
import styles from './YoutubeVideo.module.scss';

export type YoutubeVideoProps = {
  classNames?: Partial<{
    root: string;
    iframe: string;
    playButton: string;
    playerActive: string;
  }>;
} & Omit<LiteYouTubeProps, 'iframeClass' | 'playerClass' | 'wrapperClass' | 'activatedClass'>;

export const YoutubeVideo = ({
  id,
  title,
  classNames = { root: '', iframe: '', playButton: '', playerActive: '' },
  webp = true,
  poster = 'hqdefault',
  ...rest
}: YoutubeVideoProps) => {
  return (
    <LiteYouTubeEmbed
      id={id}
      title={title}
      wrapperClass={cn(styles.root, classNames?.root)}
      iframeClass={cn(styles.iframe, classNames?.iframe)}
      playerClass={cn(styles.playButton, classNames?.playButton)}
      activatedClass={cn(styles.playerActive, classNames?.playerActive)}
      webp={webp}
      poster={poster}
      {...rest}
    />
  );
};

export default YoutubeVideo;
