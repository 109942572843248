import cn from 'classnames';
import {
  AccordionProps as MaterialAccordionProps,
  AccordionClassKey as MaterialAccordionClassKey,
} from '@mui/material';
import { StyledMaterialAccordion } from './styles';

export type AccordionProps = {
  className?: string;
} & MaterialAccordionProps;

export const Accordion = ({
  children,
  classes = {},
  className,
  disabled,
  expanded,
  ...otherProps
}: AccordionProps) => {
  const compoundClasses: Partial<Record<MaterialAccordionClassKey, string>> = {
    ...classes,
    root: cn(classes.root, className),
  };

  return (
    <StyledMaterialAccordion
      classes={compoundClasses}
      disabled={disabled}
      expanded={expanded}
      {...otherProps}
    >
      {children}
    </StyledMaterialAccordion>
  );
};

export default Accordion;
