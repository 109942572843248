import cn from 'classnames';
import {
  AccordionDetailsProps as MaterialAccordionDetailsProps,
  AccordionDetailsClassKey as MaterialAccordionDetailsClassKey,
} from '@mui/material';
import { StyledMaterialAccordionDetails } from './styles';

export type AccordionDetailsProps = {
  className?: string;
} & MaterialAccordionDetailsProps;

const AccordionDetails = ({
  children,
  className,
  classes = {},
  ...otherProps
}: AccordionDetailsProps) => {
  const compoundClasses: Partial<Record<MaterialAccordionDetailsClassKey, string>> = {
    ...classes,
    root: cn(classes.root, className),
  };

  return (
    <StyledMaterialAccordionDetails {...otherProps} classes={compoundClasses}>
      {children}
    </StyledMaterialAccordionDetails>
  );
};

export default AccordionDetails;
