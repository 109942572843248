import cn from 'classnames';
import { FaqSection } from 'src/core/contentManager/entities';
import FaqItem from '../../molecules/FaqItem';
import styles from './SectionFaq.module.scss';

export type SectionFaqProps = {
  className?: string;
  sectionContent: FaqSection;
};

const SectionFaq = ({ className, sectionContent }: SectionFaqProps) => {
  const { title, description, questions } = sectionContent;
  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {description && <p className={styles.description}>{description}</p>}
        <div className={styles.faqListWrapper}>
          <ul className={styles.faqList}>
            {questions.map((question) => (
              <FaqItem key={question.id} {...question} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SectionFaq;
