import { styled } from '@mui/material/styles';
import { Accordion as MaterialAccordion } from '@mui/material';

const PREFIX = 'MuiAccordion';

const classes = {
  root: `.${PREFIX}-root`,
  expanded: `.${PREFIX}-expanded`,
};

export const StyledMaterialAccordion = styled(MaterialAccordion)`
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: none;
  border-radius: 0;

  &${classes.expanded} {
    margin: 0;
    background-color: transparent;
  }
`;
