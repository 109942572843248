import Accordion, {
  AccordionDetails,
  AccordionSummary,
} from 'src/core/common/components/common/Accordion';
import { prepareHtml } from 'src/core/common/utils/prepareHtml';

import { FaqSection } from 'src/core/contentManager/entities';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './FaqItem.module.scss';

type FaqItemProps = FaqSection['questions'][number];

const FaqItem = ({ title, text }: FaqItemProps) => {
  return (
    <li className={styles.faqListItem}>
      <Accordion className={styles.faq}>
        <AccordionSummary
          className={styles.faqHeader}
          expandIcon={<Icon name="arrow-simple" className={styles.faqHeaderIcon} />}
        >
          <h4 className={styles.faqTitle}>{title}</h4>
        </AccordionSummary>
        <AccordionDetails
          className={styles.faqContent}
          dangerouslySetInnerHTML={prepareHtml(text)}
        />
      </Accordion>
    </li>
  );
};

export default FaqItem;
