import { styled } from '@mui/material/styles';
import { AccordionSummary as MaterialAccordionSummary } from '@mui/material';
import { globalClasses } from 'src/core/common/constants/classes';

const PREFIX = 'MuiAccordionSummary';

const classes = {
  root: `.${PREFIX}-root`,
  focusVisible: `.${PREFIX}-focusVisible`,
  content: `.${PREFIX}-content`,
  expandIconWrapper: `.${PREFIX}-expandIconWrapper`,
};

export const StyledMaterialAccordionSummary = styled(MaterialAccordionSummary)`
  padding: 0;
  background-color: transparent;
  margin: 0;
  width: 100%;

  &${globalClasses.expanded} {
    margin: 0;
  }

  & ${classes.content} {
    margin: 0;

    &${globalClasses.expanded} {
      margin: 0;
    }
  }

  & ${classes.expandIconWrapper} {
    padding: 0;
    margin-right: 0;
  }
`;
