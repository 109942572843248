import {
  AccordionSummaryClassKey as MaterialAccordionSummaryClassKey,
  AccordionSummaryProps,
} from '@mui/material';
import cn from 'classnames';
import { StyledMaterialAccordionSummary } from './styles';

const AccordionSummary = ({
  children,
  classes = {},
  className,
  ...otherProps
}: AccordionSummaryProps) => {
  const compoundClasses: Partial<Record<MaterialAccordionSummaryClassKey, string>> = {
    ...classes,
    root: cn(classes.root, className),
  };

  return (
    <StyledMaterialAccordionSummary {...otherProps} classes={compoundClasses}>
      {children}
    </StyledMaterialAccordionSummary>
  );
};

export default AccordionSummary;
